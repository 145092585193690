import React, { useContext } from "react";
import { langContext } from "../../../context/langContext";
import { useDinamicHero } from "../../../hooks/useDinamicHero";

import "./dinamicHero.css";

const DinamicHero = ({ modelo }) => {
  const { locale } = useContext(langContext);
  const { currentImage } = useDinamicHero(modelo);

  if (currentImage.isVideo) {
    return (
      <section
        className="dinamicHero__bg"
        style={{
          backgroundImage: `url(${currentImage.url})`,
          backgroundPosition: `${currentImage.position}`,
          backgroundSize: `${currentImage.size}`,
        }}
      >
        <video muted autoPlay loop className="dinamicHero__video">
          <source src={currentImage.urlWp} type="video/webm" />
          <source src={currentImage.url} type="video/mp4" />
        </video>
        <div className="dinamicHero__front">
          {currentImage.logo && (
            <img
              src={
                currentImage.logoLang
                  ? locale === "en-US"
                    ? currentImage.usLogoUrl
                    : currentImage.esLogoUrl
                  : currentImage.logoUrl
              }
              alt="series logo"
              className={`${
                currentImage.logoLang
                  ? "dinamicHero__frontLogo-V"
                  : "dinamicHero__frontLogo-U"
              }`}
            />
          )}
          <h2>
            {locale === "en-US" ? currentImage.usTitle : currentImage.esTitle}
          </h2>
        </div>
      </section>
    );
  }
  return (
    <section
      className="dinamicHero__bg"
      style={{
        backgroundImage: `url(${currentImage.url})`,
        backgroundPosition: `${currentImage.position}`,
        backgroundSize: `${currentImage.size}`,
      }}
    >
      <div className="dinamicHero__front">
        {currentImage.logo && (
          <img
            src={
              currentImage.logoLang
                ? locale === "en-US"
                  ? currentImage.usLogoUrl
                  : currentImage.esLogoUrl
                : currentImage.logoUrl
            }
            alt="series logo"
            className={`${
              currentImage.logoLang
                ? "dinamicHero__frontLogo-V"
                : "dinamicHero__frontLogo-U"
            }`}
          />
        )}
        <h2>
          {locale === "en-US" ? currentImage.usTitle : currentImage.esTitle}
        </h2>
      </div>
    </section>
  );
};

export default DinamicHero;
