import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import DinamicHero from "../dinamicHero/DinamicHero";
import Media from "../media/Media";
import ProductImages from "../productImages/ProductImages";
import ProductInfo from "../productInfo/ProductInfo";

import "./product.css";
import { langContext } from "../../../context/langContext";
import TopProducts from "../../main/topProducts/TopProducts";
import SectionEvents from "../../main/sectionEvents/SectionEvents";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const Product = ({ productData }) => {
  const { modelo, serie, usLang, esLang, haveSubModelo, category } =
    productData;

  const { locale } = useContext(langContext);
  const currentLang = locale === "en-US" ? usLang : esLang;
  const imgSocials =
    productData.category === "strings"
      ? productData.rutas
      : productData.colors[0].rutas[0];

  return (
    <section className="productPage">
      <Helmet>
        <meta property="og:title" content={`${modelo} | ${serie || ""}`} />
        <meta property="og:type" content="webSite" />
        <meta property="og:url" content="www.babilonguitars.com" />
        <meta property="og:image" content={imgSocials} />
        <meta
          property="og:description"
          content={productData.usLang.description_small}
        />
        <title>{`${modelo} | ${serie || ""}`}</title>
      </Helmet>
      {serie === "Unique" && category !== "ukuleles" ? (
        <DinamicHero modelo={modelo} />
      ) : (
        <div style={{ marginTop: "120px" }}></div>
      )}

      {productData.nameCatego && (
        <div
          className="title-center title-center-b"
          style={{ padding: "0 10px" }}
        >
          <img
            style={{ width: "100%", maxWidth: "550px", margin: "50px 0" }}
            src={
              productData.nameCatego === "Eclipse"
                ? "https://i.ibb.co/HD72LfH/eclipse-Line.png"
                : "https://i.ibb.co/CJPfpfy/evolution-Line.png"
            }
            alt="logo babilon line guitars"
          />
        </div>
      )}

      {haveSubModelo && (
        <div className="subModel__container">
          {productData.subModels.models.map((el) => (
            <Link key={el.name} to={el.url} className="topProductCard">
              <img
                src={el.image}
                alt="prodcut babilon"
                className="topProductCard__img"
              />
              <h3 className="topProductCard__title">{el.name}</h3>
            </Link>
          ))}
        </div>
      )}

      {(serie !== "Unique" || category === "ukuleles") && (
        <div className="title-center title-center-b">
          <span>{modelo}</span>
        </div>
      )}

      <div className="productPage__content">
        <ProductImages productImgs={productData} />
        <ProductInfo productInfo={productData} />
      </div>
      {productData?.case && (
        <div style={{ margin: "40px 0" }}>
          <div>
            <h3
              style={{
                textAlign: "center",
                fontSize: "3rem",
              }}
            >
              <FormattedMessage
                id="productUnique.case"
                defaultMessage="Case Included"
              />
            </h3>
          </div>
          <div className="productPage__cases">
            {productData.cases.map((el, index) => (
              <img
                key={index}
                className="cases__img"
                src={el}
                alt="case babilon"
              />
            ))}
          </div>
        </div>
      )}
      <TopProducts />
      <SectionEvents />
      {/* <Media /> */}
    </section>
  );
};

export default Product;
