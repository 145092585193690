import React from "react";
import { useStringsCategory } from "../../../hooks/useStringsCategory";
import CardProduct from "../cardProduct/CardProduct";
import SkeletonProductCards from "../../skeletons/SkeletonProductCards";
import "./stringsCategory.css";

const StringsCategory = () => {
  const { Basics, Professionals, Masters, Premiums, loader } =
    useStringsCategory();

  return loader ? (
    <SkeletonProductCards />
  ) : (
    <section>
      {Premiums.length > 0 ? (
        <div>
          <h2 className="strings__Title">Premium</h2>
          <div className="categoryStrings__list">
            <div className="categoryStrings__list-ft">
              {Premiums.map((el, index) => (
                <CardProduct key={index} prod={el} />
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
      {Masters.length > 0 ? (
        <div>
          <h2 className="strings__Title">Master</h2>
          <div className="categoryStrings__list">
            <div className="categoryStrings__list-ft">
              {Masters.map((el, index) => (
                <CardProduct key={index} prod={el} />
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
      {Professionals.length > 0 ? (
        <div>
          <h2 className="strings__Title">Professional</h2>
          <div className="categoryStrings__list">
            <div className="categoryStrings__list-ft">
              {Professionals.map((el, index) => (
                <CardProduct key={index} prod={el} />
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
      {Basics.length > 0 ? (
        <div>
          <h2 className="strings__Title">Basics</h2>
          <div className="categoryStrings__list">
            <div className="categoryStrings__list-ft">
              {Basics.map((el, index) => (
                <CardProduct key={index} prod={el} />
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </section>
  );
};

export default StringsCategory;
