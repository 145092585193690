import React from "react";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";
import ReactDOM from "react-dom/client";
import { HashRouter } from "react-router-dom";
import App from "./App";
import { LoginProvider } from "./context/loginContext";

if (process.env.NODE_ENV === "production") {
  disableReactDevTools();
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <HashRouter>
    <LoginProvider>
      <App />
    </LoginProvider>
  </HashRouter>
);
