import React from "react";
import { FormattedMessage } from "react-intl";
import { useProductImages } from "../../../hooks/useProductImages";
import SkeletonImages from "../../skeletons/SkeletonImages";

import "./productImages.css";
import SliderThumbs from "../sliderThumbs/SliderThumbs";

const ProductImages = ({ productImgs }) => {
  const {
    mainImg,
    mainIMG,
    category,
    allColors,
    handleColorImages,
    handleMainIMG,
    loading,
  } = useProductImages(productImgs);
  return loading ? (
    <SkeletonImages />
  ) : (
    <div className="productPage__images">
      <div className="productPage__images-top">
        <div className="productPage__images-main">
          <img
            className="productPage__main-img"
            src={mainImg[mainIMG]}
            alt="producto babilon"
          />
        </div>
        {category === "strings" ? (
          <div></div>
        ) : (
          <div>
            <h3 className="productPage__colors-title">
              <FormattedMessage id="product.colors" defaultMessage="Colors:" />
            </h3>
            <div className="productPage__colors">
              {allColors?.map((el, index) => (
                <div
                  className="productPage__color-border"
                  key={index}
                  onClick={() => handleColorImages(index)}
                >
                  <div
                    className="productPage__color"
                    style={{ background: `${el?.code}` }}
                  ></div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      {productImgs.serie !== "Unique" ? (
        <div className="productPage__images-thumbs">
          {mainImg?.map((el, index) => (
            <img
              className="productPage__thumbs-item"
              src={el}
              alt="producto babilon"
              key={index}
              onClick={() => handleMainIMG(index)}
            />
          ))}
        </div>
      ) : (
        <SliderThumbs data={mainImg} handleMainIMG={handleMainIMG} />
      )}
    </div>
  );
};

export default ProductImages;
