import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { getEvents } from "../../../services/events";
import EventCard from "../../events/eventCard/EventCard";
import "./sectionEvents.css";

const SectionEvents = () => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    getEvents().then((data) => setEvents(data));
  }, []);

  return (
    <section className="section__events">
      <h2 className="title-center">
        <FormattedMessage id="events.title" defaultMessage="Events" />
      </h2>
      <div className="events__list">
        {events.map((even) => (
          <EventCard key={`${even._id}-eve`} infoEvent={even} />
        ))}
      </div>
    </section>
  );
};

export default SectionEvents;
