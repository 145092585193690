import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { getTopProducts } from "../../../services/products";
import Slider from "../slider/Slider";

import "./topProducts.css";

const TopProducts = ({ type }) => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    if (type === "strings") {
      getTopProducts().then((data) =>
        setProducts(data.filter((prod) => prod.category === "strings"))
      );
    } else {
      getTopProducts().then((data) => {
        const guitarData = data.filter((prod) => prod.category !== "strings");
        const withoutLegg = guitarData.filter((el) => el.modelo !== "Leggend");
        setProducts([guitarData[2], ...withoutLegg]);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="topProducts">
      <div className="title__left">
        {type === "strings" ? (
          <span>
            <FormattedMessage
              id="footer.products-item4"
              defaultMessage="Strings"
            />
          </span>
        ) : (
          <span>
            <FormattedMessage
              id="topProducts.title"
              defaultMessage="Top products"
            />
          </span>
        )}
        <div className="title__left-line"></div>
      </div>
      <Slider dataSlide={products} />
    </section>
  );
};

export default TopProducts;
