import { useParams } from 'react-router-dom';
import { getDataHero } from '../helpers/heroHelper';

export const useDinamicHero = (modelo) => {
  const { category, serie, product } = useParams();
  let currentParam = '';

  // console.log(category);

  // console.log(serie);

  // console.log(product);

  if (modelo === undefined) {
    if (
      category === 'Guitars' &&
      serie === undefined &&
      product === undefined
    ) {
      currentParam = 'guitars';
    } else if (
      category === 'Guitars' &&
      serie === 'Unique' &&
      product === undefined
    ) {
      currentParam = 'unique';
    } else if (
      category === 'Guitars' &&
      serie === 'B-Series' &&
      product === undefined
    ) {
      currentParam = 'b-series';
    } else if (
      category === 'Guitars' &&
      serie === undefined &&
      product === 'Acoustic-Electric'
    ) {
      currentParam = 'acoustic-electric';
    } else if (
      category === 'Guitars' &&
      serie === undefined &&
      product === 'Acoustic'
    ) {
      currentParam = 'acoustic';
    } else if (
      category === 'Bass' &&
      serie === undefined &&
      product === undefined
    ) {
      currentParam = 'bass';
    } else if (
      category === 'Bass' &&
      serie === 'Unique' &&
      product === undefined
    ) {
      currentParam = 'bassUnique';
    } else if (
      category === 'Bass' &&
      serie === 'B-Series' &&
      product === undefined
    ) {
      currentParam = 'bassBseries';
    } else if (
      category === 'Ukuleles' &&
      serie === undefined &&
      product === undefined
    ) {
      currentParam = 'ukuleles';
    } else if (
      category === 'Ukuleles' &&
      serie === 'Unique' &&
      product === undefined
    ) {
      currentParam = 'ukuleleUnique';
    } else if (
      category === 'Ukuleles' &&
      serie === 'B-Series' &&
      product === undefined
    ) {
      currentParam = 'ukulelesBseries';
    } else if (
      category === 'Strings' &&
      serie === undefined &&
      product === 'Guitar'
    ) {
      currentParam = 'stringsGuitar';
    } else if (
      category === 'Strings' &&
      serie === undefined &&
      product === 'Bass'
    ) {
      currentParam = 'stringsBass';
    } else if (
      category === 'Strings' &&
      serie === undefined &&
      product === 'Ukulele'
    ) {
      currentParam = 'stringsUkulele';
    } else if (
      category === undefined &&
      serie === 'B-Series' &&
      product === undefined
    ) {
      currentParam = 'b-series';
    }
  } else {
    currentParam = modelo;
  }
  const currentImage = getDataHero(currentParam);
  return { currentImage };
};
