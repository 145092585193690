// import VIDEO from "../assets/video/Loop UNIQUE.mp4";
// import VIDEOWEB from "../assets/video/Loop UNIQUE.webm";

export const getDataHero = (option) => {
  let heroInfo = {};
  switch (option) {
    case 'guitars':
      heroInfo = {
        url: 'https://i.ibb.co/3pjz2SV/hero-Guitars-New.jpg',
        position: 'center',
        size: 'cover',
        esTitle: 'GUITARRAS',
        usTitle: 'GUITARS',
        logo: false,
        isVideo: false,
      };
      break;
    case 'acoustic':
      heroInfo = {
        url: 'https://i.ibb.co/QQmf73S/GUITAR.png',
        position: 'center',
        size: 'cover',
        esTitle: 'ACUSTICA',
        usTitle: 'ACOUSTIC',
        logo: false,
        isVideo: false,
      };
      break;
    case 'acoustic-electric':
      heroInfo = {
        url: 'https://i.ibb.co/QQmf73S/GUITAR.png',
        position: 'center',
        size: 'cover',
        esTitle: 'ELECTRO-ACUSTICA',
        usTitle: 'ACOUSTIC-ELECTRIC',
        logo: false,
        isVideo: false,
      };
      break;
    case 'bass':
      heroInfo = {
        url: 'https://i.ibb.co/GHMrwMm/basses-Hero-New.jpg',
        position: 'center',
        size: 'cover',
        esTitle: 'BAJOS',
        usTitle: 'BASSES',
        logo: false,
        isVideo: false,
      };
      break;
    case 'bassUnique':
      heroInfo = {
        url: 'https://i.ibb.co/GHMrwMm/basses-Hero-New.jpg',
        position: 'center',
        size: 'cover',
        esTitle: 'SERIES',
        usTitle: 'SERIES',
        logo: true,
        logoLang: false,
        logoUrl: 'https://i.ibb.co/BfL26yz/unique-bg.png',
      };
      break;
    case 'ukuleleUnique':
      heroInfo = {
        url: 'https://i.ibb.co/6wXP4H3/ukulele-Hero-New.jpg',
        position: 'center',
        size: 'cover',
        esTitle: 'SERIES',
        usTitle: 'SERIES',
        logo: true,
        logoLang: false,
        logoUrl: 'https://i.ibb.co/BfL26yz/unique-bg.png',
      };
      break;
    case 'ukuleles':
      heroInfo = {
        url: 'https://i.ibb.co/6wXP4H3/ukulele-Hero-New.jpg',
        position: '0 97%',
        size: 'contain',
        esTitle: 'UKULELES',
        usTitle: 'UKULELES',
        logo: false,
        isVideo: false,
      };
      break;
    case 'ukulelesBseries':
      heroInfo = {
        url: 'https://i.ibb.co/k17N4Kn/ukubseries-min.jpg',
        position: 'center 50%',
        size: 'cover',
        esTitle: 'B-SERIES',
        usTitle: 'B-SERIES',
        logo: false,
        isVideo: false,
      };
      break;
    case 'stringsGuitar':
      heroInfo = {
        url: 'https://i.ibb.co/jR10zHs/string-Guitars-Hero-min.jpg',
        position: 'center',
        size: 'cover',
        esTitle: 'GUITARRA',
        usTitle: 'GUITAR',
        logo: false,
        isVideo: false,
      };
      break;
    case 'stringsBass':
      heroInfo = {
        url: 'https://i.ibb.co/PtDytXf/strings-Bass-Hero-min.jpg',
        position: 'center',
        size: 'cover',
        esTitle: 'BAJO',
        usTitle: 'BASS',
        logo: false,
        isVideo: false,
      };
      break;
    case 'stringsUkulele':
      heroInfo = {
        url: 'https://i.ibb.co/KXvSXhM/strings-Uku-Hero-min.jpg',
        position: 'center',
        size: 'cover',
        esTitle: 'UKULELE',
        usTitle: 'UKULELE',
        logo: false,
        isVideo: false,
      };
      break;
    case 'packs':
      heroInfo = {
        url: 'https://i.ibb.co/7kpBHSc/packs.png',
        position: 'center',
        size: 'cover',
        esTitle: 'PAQUETES',
        usTitle: 'PACKS',
        logo: false,
        isVideo: false,
      };
      break;
    case 'bassBseries':
      heroInfo = {
        url: 'https://i.ibb.co/kDMZMMT/hero-Bass-Bserie-New-min.jpg',
        position: 'center',
        size: 'cover',
        esTitle: 'B-SERIES',
        usTitle: 'B-SERIES',
        logo: false,
        isVideo: false,
      };
      break;
    // case 'unique':
    //   heroInfo = {
    //     url: 'https://i.ibb.co/3vQqkVs/ninoHero.png',
    //     position: '20% 80%',
    //     size: 'cover',
    //     esTitle: 'UNIQUE',
    //     usTitle: 'UNIQUE',
    //     logo: false,
    //     isVideo: false,
    //   };
    //   break;
    case 'b-series':
      heroInfo = {
        url: 'https://i.ibb.co/9bmrDwm/hero-Bseries-New-min.jpg',
        position: 'center',
        size: 'cover',
        esTitle: 'B-SERIES',
        usTitle: 'B-SERIES',
        logo: false,
        isVideo: false,
      };
      break;
    case 'Master IV':
      heroInfo = {
        url: 'https://i.ibb.co/XsMMMxC/MASTER-HERO.jpg',
        position: 'center',
        size: 'cover',
        esTitle: 'MASTER IV',
        usTitle: 'MASTER IV',
        logo: false,
        isVideo: false,
      };
      break;
    case 'Master V':
      heroInfo = {
        url: 'https://i.ibb.co/XsMMMxC/MASTER-HERO.jpg',
        position: 'center 60%',
        size: 'cover',
        esTitle: 'MASTER V',
        usTitle: 'MASTER V',
        logo: false,
        isVideo: false,
      };
      break;
    case 'Leggend':
      heroInfo = {
        url: 'https://i.ibb.co/HdMCQTz/leggend-2.jpg',
        position: '20% 40%',
        size: 'cover',
        esTitle: 'LEGGEND',
        usTitle: 'LEGGEND',
        logo: false,
        isVideo: false,
      };
      break;
    case 'Cosmos II':
      heroInfo = {
        url: 'https://i.ibb.co/vVxcvgZ/cosmos-II-hero-min.jpg',
        position: 'center',
        size: 'cover',
        esTitle: 'COSMOS II',
        usTitle: 'COSMOS II',
        logo: false,
        isVideo: false,
      };
      break;
    case 'Cosmos':
      heroInfo = {
        url: 'https://i.ibb.co/JRYyrwM/cosmos-hero-min.jpg',
        position: 'center',
        size: 'cover',
        esTitle: 'COSMOS',
        usTitle: 'COSMOS',
        logo: false,
        isVideo: false,
      };
      break;
    default:
      heroInfo = {
        // url: VIDEO,
        // urlWp: VIDEOWEB,
        url: 'https://i.ibb.co/DQYQc2H/unique-series.jpg',
        position: 'center 45%',
        size: 'cover',
        esTitle: 'SERIES',
        usTitle: 'SERIES',
        logo: true,
        logoLang: false,
        logoUrl: 'https://i.ibb.co/BfL26yz/unique-bg.png',
        isVideo: true,
      };
      break;
  }
  return heroInfo;
};

// {
//   "content": false,
//   "labelEs": "Tienda",
//   "labelUs": "Shop",
//   "lang": true,
//   "redirectTo": "https://hermesmusic.com/collections/babilon",
//   "redirectToEs": "https://hermesmusicclub.mx/collections/vendors?q=BABILON"
// }
