// import VIDEO from "../../../assets/video/loop vintage website_1.webm";
// import VIDEOMP4 from "../../../assets/video/loop_home.mp4";
// import IMG from "../../../assets/imgs/Babilon editable-min.png";
import IMG2 from "../../../assets/imgs/mainHero.jpeg";

// librerías
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";

// hooks
import useWindowSize from "../../../hooks/useWindowSize";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// styles
import "./hero.css";

// import required modules
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

const Hero = () => {
  const { width } = useWindowSize();

  return (
    <section className="hero">
      <Swiper
        slidesPerView={1}
        loop={true}
        pagination={true}
        modules={[Autoplay, Pagination]}
        className="hero__slider"
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        spaceBetween={30}
      >
        {width > 1044 && (
          <SwiperSlide className="slider__item">
            <div className="item__info">
              <div
                className="info__container help_container"
                style={{ width: "90%" }}
              >
                <div
                  className="info__c"
                  style={{
                    color: "var(--white)",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {/* <h3 className="hero__title">LEGGEND</h3> */}
                  <img
                    className="info__logo-main"
                    src="https://i.ibb.co/kDmbc2Y/babilon-morado-new.png"
                    alt="logo babilon"
                  />
                  {/* <span className="hero__subTitle">Redefinig the sound</span>
                <Link
                  to="products/guitars/electric/65d636a32d55000012ff8ee5"
                  className="info__btn"
                >
                  <FormattedMessage
                    id="hero.learn"
                    defaultMessage="LEARN MORE"
                  />
                </Link> */}
                </div>
              </div>
            </div>
            <img
              src={IMG2}
              alt="babilon guitars hero"
              className="hero__img"
              style={{ objectPosition: "3% center" }}
            />
          </SwiperSlide>
        )}
        <SwiperSlide className="slider__item">
          <div className="item__info">
            <div
              className="info__container"
              style={{
                justifyContent: "flex-start",
              }}
            >
              <div
                className="info__c"
                style={{ color: "var(--white)", alignItems: "flex-start" }}
              >
                <h3 className="hero__title">MASTER BASS IV</h3>
                <img
                  className="info__logo"
                  src="https://i.ibb.co/BfL26yz/unique-bg.png"
                  alt="logo babilon"
                />
                <span className="hero__subTitle">
                  Vibrate with your universe
                </span>
                <Link
                  to="Series/Unique/Bass"
                  className="info__btn"
                  style={{
                    marginLeft: "10px",
                  }}
                >
                  <FormattedMessage
                    id="hero.learn"
                    defaultMessage="LEARN MORE"
                  />
                </Link>
              </div>
              <span className="hero__name position-left-to-right">
                Karla Molkovich
              </span>
            </div>
          </div>
          <img
            src="https://i.ibb.co/XsMMMxC/MASTER-HERO.jpg"
            alt="babilon guitars hero"
            className="hero__img"
            style={{ objectPosition: "53% center" }}
          />
        </SwiperSlide>
        <SwiperSlide className="slider__item">
          <div className="item__info">
            <div
              className="info__container"
              style={{
                justifyContent: "flex-start",
              }}
            >
              <div
                className="info__c"
                style={{ color: "var(--white)", alignItems: "flex-start" }}
              >
                <span className="hero__leabel-new-mbv ">
                  <FormattedMessage id="hero.new" defaultMessage="New!" />
                </span>
                <h3 className="hero__title">MASTER BASS V</h3>
                <img
                  className="info__logo"
                  src="https://i.ibb.co/BfL26yz/unique-bg.png"
                  alt="logo babilon"
                />
                <span className="hero__subTitle">
                  Vibrate with your universe
                </span>
                <Link
                  to="Series/Unique/Bass"
                  className="info__btn"
                  style={{
                    marginLeft: "10px",
                  }}
                >
                  <FormattedMessage
                    id="hero.learn"
                    defaultMessage="LEARN MORE"
                  />
                </Link>
              </div>
              <span className="hero__name position-left-to-right">
                Sabo Romo
              </span>
            </div>
          </div>
          <img
            src="https://i.ibb.co/26qrzJZ/banner-sabo-romo.jpg"
            alt="babilon guitars hero"
            className="hero__img"
            style={{ objectPosition: "81% 10%" }}
          />
        </SwiperSlide>
        <SwiperSlide className="slider__item">
          <div className="item__info">
            <div className="info__container">
              <div
                className="info__c"
                style={{ color: "var(--white)", alignItems: "flex-end" }}
              >
                <h3 className="hero__title">LEGGEND</h3>
                <img
                  className="info__logo"
                  src="https://i.ibb.co/BfL26yz/unique-bg.png"
                  alt="logo babilon"
                />
                <span className="hero__subTitle">Redefining the sound</span>
                <Link
                  to="products/guitars/electric/65d636a32d55000012ff8ee5"
                  className="info__btn"
                >
                  <FormattedMessage
                    id="hero.learn"
                    defaultMessage="LEARN MORE"
                  />
                </Link>
              </div>
              <span className="hero__name position-right-to-left">
                Nino Oxilia
              </span>
            </div>
          </div>
          <img
            src="https://i.ibb.co/PFh0PMV/hero-real.jpg"
            alt="babilon guitars hero"
            className="hero__img"
            style={{ objectPosition: "20% 30%" }}
          />
        </SwiperSlide>
        <SwiperSlide className="slider__item">
          <div className="item__info">
            <div
              className="info__container"
              style={{
                justifyContent: "flex-start",
              }}
            >
              <div
                className="info__c"
                style={{
                  color: "var(--white)",
                  alignItems: "flex-start",
                }}
              >
                <span className="hero__leabel-new-bs">
                  <FormattedMessage id="hero.new" defaultMessage="New!" />
                </span>
                <h3 className="hero__title">B-SERIES</h3>
                <img
                  className="info__logo"
                  src="https://i.ibb.co/zmSqsnd/evolution-white.png"
                  alt="logo babilon"
                  style={{ marginBottom: "10px" }}
                />
                <Link
                  to="products/guitars/electric/65d62e28a2e3ea0012358501"
                  className="info__btn"
                >
                  <FormattedMessage
                    id="hero.learn"
                    defaultMessage="LEARN MORE"
                  />
                </Link>
              </div>
              <span className="hero__name position-left-to-right">Rani</span>
            </div>
          </div>
          <img
            src="https://i.ibb.co/VQrZQ8c/rani-hero.jpg"
            alt="babilon guitars hero"
            className="hero__img"
            style={{ objectPosition: "80% 0" }}
          />
        </SwiperSlide>
        {/* <SwiperSlide className="slider__item">
          <div className="item__info">
            <div
              className="info__container"
              style={{
                justifyContent: "flex-start",
              }}
            >
              <div className="info__c" style={{ color: "var(--white)", alignItems: "flex-start" }}>
                <h3 className="hero__title">COSMOS II</h3>
                <img
                  className="info__logo"
                  src="https://i.ibb.co/BfL26yz/unique-bg.png"
                  alt="logo babilon"
                />
                <span className="hero__subTitle">Classy(c) Sound</span>
                <Link to="products/guitars/electric/65d63651d9d0d0001254a144" className="info__btn">
                  <FormattedMessage id="hero.learn" defaultMessage="LEARN MORE" />
                </Link>
              </div>
            </div>
          </div>
          <img
            src={IMG}
            alt="babilon guitars hero"
            className="hero__img"
            style={{ objectPosition: "77% 30%" }}
          />
        </SwiperSlide> */}
        {/* <SwiperSlide className="slider__item">
          <div className="item__info">
            <div
              className="info__container"
              style={{
                justifyContent: "flex-start",
              }}
            >
              <div
                className="info__c"
                style={{ color: "var(--white)", alignItems: "flex-start" }}
              >
                <h3 className="hero__title">COSMOS</h3>
                <img
                  className="info__logo"
                  src="https://i.ibb.co/BfL26yz/unique-bg.png"
                  alt="logo babilon"
                />
                <span className="hero__subTitle">
                  Vibrate with your universe
                </span>
                <Link
                  to="products/guitars/electric/65d6367cd9d0d0001254a145"
                  className="info__btn"
                >
                  <FormattedMessage
                    id="hero.learn"
                    defaultMessage="LEARN MORE"
                  />
                </Link>
              </div>
            </div>
          </div>
          <img
            src="https://i.ibb.co/JRYyrwM/cosmos-hero-min.jpg"
            alt="babilon guitars hero"
            className="hero__img"
          />
        </SwiperSlide> */}
      </Swiper>
      {/* <video muted autoPlay loop className="hero__video">
        <source src={VIDEO} type="video/webm" />
        <source src={VIDEOMP4} type="video/mp4" />
      </video>
      <div className="hero__shadow">
        <div className="hero__content">
          <div className="hero__content-wrapper">
            <h2 className="hero__slogan-serie">
              <FormattedMessage
                id="header.uniqueLabel"
                defaultMessage="Meet the new line"
              />
            </h2>
            <img
              className="hero__logo-serie"
              src="https://i.ibb.co/BfL26yz/unique-bg.png"
              alt="serie unique babilon"
            />
            <h2 className="hero__slogan">
              <FormattedMessage
                id="header.uniqueSlogan"
                defaultMessage="¡Vibrate with your universe!"
              />
            </h2>

            <img
              className="hero__logoB"
              src="https://i.ibb.co/MDdv5jy/babilon-blanco-simple-new.png"
              alt="logo babilon"
            />
          </div>
        </div>
      </div> */}
    </section>
  );
};

export default Hero;
